// import request from '@/utils/request'
import request from '@/utils/request_mall'

// 手机号登录
export function login(data) {
  return request({
    method: 'post',
    url: '/backend/login_verify',
    data
  })
}
// 密码登录
export function pslogin(data) {
  return request({
    method: 'post',
    url: '/login',
    headerType: 'json',
    data
  })
}

// 登出
export function logout(data) {
  return request({
    method: 'post',
    url: '',
    mockUrl: '/mock/user/logout',
    data
  })
}

// 获取用户信息
export function queryUser(data) {
  return request({
    method: 'post',
    url: '',
    mockUrl: '/mock/user/info',
    data,
    params: {
      t: Date.now()
    }
  })
}

// 获取菜单树
export function queryMenus(data) {
  return request({
    method: 'post',
    url: '',
    mockUrl: '/mock/menus',
    data
  })
}


// 重置密码（用于未登录）
export function resetPassword(data) {
  return request({
    method: 'post',
    closeAutoTips: true,
    url: '/change_password',
    // mockUrl: '/mock/user/reset/password',
    headerType: 'json',
    data
  })
}
// 重置密码 获取 短信验证码
export function getpsSmsCode(data) {
  return request({
    method: 'post',
    closeAutoTips: true,
    url: '/change_password_send',
    // mockUrl: '/mock/user/sms/send',
    headerType: 'json',
    data
  })
}

// 重置密码（用于已登录）
export function modifyPassword(data) {
  return request({
    method: 'post',
    closeAutoTips: true,
    url: '',
    mockUrl: '/mock/user/reset/password',
    headerType: 'json',
    data
  })
}

// 注册
export function register(data) {
  return request({
    method: 'post',
    closeAutoTips: true,
    url: '',
    mockUrl: '/mock/user/register',
    headerType: 'json',
    data
  })
}
// 登录相关 
export function GetCompany(data) {
  return request({
    method: 'post',
    // url: '/backend/v1/get_company_list',
    url: '/backend/login_company',
    data
  })
}
// 验证码登陆
export function Login(data) {
  return request({
    method: 'post',
    url: '/backend/login_verify',
    data
  })
}
// 获取短信验证码
export function getSmsCode(data) {
  return request({
    method: 'post',
    closeAutoTips: true,
    url: '/backend/login_send',
    data
  })
}

